import React from 'react';
import { makeStyles, Theme, createStyles, Fab, Hidden, Box } from '@material-ui/core';
import HideOnScroll from '../shared/HideOnScroll';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

interface FloatingActionButton {
  title?: string;
  icon: JSX.Element;
  handleClick: () => void;
  handleCreateExclusion?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      position: 'fixed',
      bottom: theme.spacing(2.5),
      right: theme.spacing(2),
    },
  })
);

const FloatingActionButton = ({
  title,
  icon,
  handleClick,
  handleCreateExclusion,
}: FloatingActionButton) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const location = useLocation();
  const inReport = location.pathname.includes('report');
  const inExclusions = location.pathname.includes('exclusions');
  const inFranchisees = location.pathname.includes('franchisees');

  return (
    <Hidden implementation="css" lgUp>
      <HideOnScroll>
        <Box className={classes.fab}>
          {(inExclusions || inFranchisees) && (
            <Fab
              variant={title ? 'extended' : 'round'}
              size="medium"
              color="primary"
              aria-label="filter"
              onClick={() => handleCreateExclusion && handleCreateExclusion()}
              style={{ borderRadius: '5px 0px 0px 5px' }}
            >
              {`+ ${t('reports.labels.addExclusion')}`}
            </Fab>
          )}
          <Fab
            variant={title ? 'extended' : 'round'}
            size="medium"
            color="primary"
            aria-label="filter"
            onClick={handleClick}
            style={inExclusions || inFranchisees ? { borderRadius: '0px 5px 5px 0px' } : undefined}
          >
            {icon}
            {title}
          </Fab>
        </Box>
      </HideOnScroll>
    </Hidden>
  );
};

export default FloatingActionButton;
